import { SecureWalletUxMode } from '@moonpay/login-common';
import { FC } from 'react';
import { PromptContainer } from 'src/components/PromptContainer';
import WithWallet from 'src/components/WithWallet';
import { FinalizeFlowParams } from 'src/moonpayApi/finalizeDhwFlow';
import BackupWalletFlow from 'src/wallet/components/BackupWalletFlow';
import BackupWalletManually from 'src/wallet/components/BackupWalletManually';
import SeedPhrasePreview from 'src/wallet/components/SeedPhrasePreview';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';

interface SecureWalletProps {
  walletStorage: WalletStorage;
  uxMode: SecureWalletUxMode;
  onWalletBackedUpSuccess: (params: FinalizeFlowParams) => Promise<void>;
  promptRef: React.RefObject<HTMLDivElement>;
}

const SecureWallet: FC<SecureWalletProps> = ({
  uxMode,
  walletStorage,
  onWalletBackedUpSuccess,
  promptRef,
}: SecureWalletProps) => {
  switch (uxMode) {
    case SecureWalletUxMode.NoUx:
      return <></>;

    case SecureWalletUxMode.ShowSeedPhrase:
      return (
        <WithWallet walletStorage={walletStorage}>
          {(wallet) => (
            <SeedPhrasePreview seedPhrase={wallet.mnemonic.phrase} />
          )}
        </WithWallet>
      );

    case SecureWalletUxMode.VerifySeedPhrase:
      return (
        <WithWallet walletStorage={walletStorage}>
          {(wallet) => (
            <BackupWalletFlow
              wallet={wallet}
              onWalletBackedUpSuccess={onWalletBackedUpSuccess}
              BackupComponent={BackupWalletManually}
              backupType="manual"
            />
          )}
        </WithWallet>
      );

    case SecureWalletUxMode.Prompt:
      return <PromptContainer ref={promptRef} />;

    default:
      return null;
  }
};

export default SecureWallet;
