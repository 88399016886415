import styled from 'styled-components';

const VerificationCodeContainer = styled.div<{
  columns: number;
  maxColumnSize?: number;
}>`
  display: grid;
  grid-template-columns: ${(props) =>
    `repeat(${props.columns}, minmax(2rem, ${props.maxColumnSize ?? 4}rem))`};
  grid-template-rows: 4rem;
  gap: 0.5rem;
  justify-content: center;
`;

export default VerificationCodeContainer;
