import { FC } from 'react';
import { Logger } from 'src/utils/logger';
import useDecryptedWallet from 'src/wallet/hooks/useDecryptedWallet';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import { AbstractWallet } from 'src/wallet/types/Wallet';

const logger = new Logger(__filename);

interface Props {
  walletStorage: WalletStorage;
  children: (wallet: AbstractWallet) => React.ReactElement;
}

const WithWallet: FC<Props> = ({ walletStorage, children }) => {
  const wallet = useDecryptedWallet({
    walletStorage,
  });

  if (!wallet) {
    logger.logError(
      'WithWallet',
      'Tried to retrieve and decrypt wallet but it has come back as undefined',
    );
    console.error(
      `Tried to retrieve and decrypt wallet but it has come back as undefined`,
    );
    return null;
  }

  return children(wallet);
};

export default WithWallet;
