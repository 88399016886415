import clsx from 'clsx';
import {
  createElement,
  ElementType,
  PropsWithChildren,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import Text from '../Text';

import { InfoBoxProps } from './types';

const InfoBoxContainer = styled.div<{ isDarkMode: boolean }>`
  box-shadow: ${(props) =>
    !props.isDarkMode &&
    `2px 2px 8px ${props.theme.utils?.hexToRgba(
      props.theme.colors.lightThemePrimary,
      0.1,
    )}`};
  border-radius: var(--foreground-radius);
  background: var(--sys-background-secondary);
`;

const InfoBox = ({
  Icon,
  className,
  children,
  helperText,
  variant = 'default',
}: PropsWithChildren<InfoBoxProps>): JSX.Element => {
  let content: ReactNode;

  const isDarkMode = document.body.classList.contains('dark');

  const renderIcon = (): JSX.Element =>
    Icon ? (
      <div
        className="text-accent-primary mr-2"
        data-testid="infoBox-icon-container"
      >
        {createElement(Icon as ElementType, {
          className: 'inline w-6 h-6',
        })}
      </div>
    ) : (
      <></>
    );

  switch (variant) {
    case 'withHelperText':
      return (
        <InfoBoxContainer
          isDarkMode={isDarkMode}
          className={clsx(
            'p-4 text-label-secondary flex items-center flex-col text-left',
            className,
          )}
        >
          <div className="flex items-center w-full">
            {renderIcon()}
            <Text
              variant="subhead"
              as="div"
              bold
              className="text-label-secondary"
            >
              {children}
            </Text>
          </div>
          <Text variant="footnote" as="div" className="mt-2">
            {helperText}
          </Text>
        </InfoBoxContainer>
      );

    default:
      content = (
        <InfoBoxContainer
          isDarkMode={isDarkMode}
          className={clsx(
            'p-4 text-label-secondary flex items-center flex-row flex-nowrap',
            className,
          )}
        >
          {renderIcon()}
          <Text variant="footnote" as="div" className="flex-1">
            {children}
          </Text>
        </InfoBoxContainer>
      );
  }

  return <>{content}</>;
};

export default InfoBox;
