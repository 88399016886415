import { ButtonMargin, ButtonRadius, ButtonVariant } from "./types";

export const getMarginClassName = (margin: ButtonMargin): string => {
  switch (margin) {
    case 'small':
      return 'mt-2 xs:mt-4';
    case 'none':
      return '';
    default:
      return 'mt-4 xs:mt-8';
  }
};

export const getRadiusClassName = (margin: ButtonRadius): string => {
  switch (margin) {
    case 'small':
      return 'rounded-lg';
    default:
      return 'rounded-button';
  }
};

export const getColorsForVariant = (variant: ButtonVariant): string => {
  switch (variant) {
    case 'custom':
      return '';
    case 'text':
      return 'text-label-primary disabled:text-label-tertiary';
    case 'danger':
      return 'text-btn-label bg-accent-quaternary disabled:bg-fill-secondary';
    case 'clear':
      return 'text-accent-primary';
    case 'primary':
    default:
      return 'bg-btn-primary hover:bg-btn-hover disabled:bg-fill-secondary text-btn-label disabled:text-label-tertiary';
  }
};

export const getLoaderColorForVariant = (variant: ButtonVariant): string => {
  switch (variant) {
    case 'custom':
    case 'text':
      return '#000000';
    case 'primary':
    default:
      return '#ffffff';
  }
};
