import { ArrowCircleRightIcon, XCircleIcon } from '@heroicons/react/outline';
import { FC, FunctionComponent } from 'react';
import styled from 'styled-components';
import Icon from '../Icon';
import Text from '../Text';

interface CurrencyXCurrencyProps {
  currencyFrom: {
    name: string;
    icon: FunctionComponent;
  };
  currencyTo: {
    name: string;
    icon: FunctionComponent;
  };
  isNotAllowed?: boolean;
}
const ArrowIcon = styled(ArrowCircleRightIcon)`
  width: 2rem;
  height: 2rem;
  translate: 0rem -1rem;
  path {
    color: #7d00ff;
    stroke-width: 0.05rem;
  }
`;
const CrossIcon = styled(XCircleIcon)`
  width: 2rem;
  height: 2rem;
  translate: 0rem -1rem;
  path {
    color: #ff0000;
    stroke-width: 0.05rem;
  }
`;
const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const Dot = styled.span<{ opacity: number }>`
  background: var(--accent-primary);
  height: 0.25rem;
  width: 0.25rem;
  margin: 0 0.25rem;
  border-radius: 50%;
  opacity: ${({ opacity }) => opacity / 100};
`;
const CurrencyXCurrency: FC<CurrencyXCurrencyProps> = ({
  currencyFrom,
  currencyTo,
  isNotAllowed = false,
}) => {
  return (
    <div className="flex items-center justify-center w-full">
      <div className="flex flex-col mx-1 border-divider-nonOpaque mb-6 items-center">
        <Icon
          className="m-auto"
          icon={currencyFrom.icon}
          iconAlt={currencyFrom.name}
        />
        <Text
          variant="footnote"
          as="div"
          className="mt-2 text-center text-label-primary"
        >
          {currencyFrom.name}
        </Text>
      </div>
      {!isNotAllowed ? (
        <DotsContainer>
          {Array(6)
            .fill(0)
            .map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Dot key={index} opacity={40} />
            ))}
          <ArrowIcon />
          {Array(6)
            .fill(0)
            .map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Dot key={index} opacity={40} />
            ))}
        </DotsContainer>
      ) : (
        <DotsContainer>
          {Array(6)
            .fill(0)
            .map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Dot key={index} opacity={40} style={{ background: '#FF0000' }} />
            ))}
          <CrossIcon />
          {Array(6)
            .fill(0)
            .map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Dot key={index} opacity={40} style={{ background: '#FF0000' }} />
            ))}
        </DotsContainer>
      )}

      <div className="flex flex-col mx-1 border-divider-nonOpaque mb-6 items-center">
        <Icon icon={currencyTo.icon} iconAlt={currencyTo.name} />
        <Text
          variant="footnote"
          className="mt-2 text-center text-label-primary"
        >
          {currencyTo.name}
        </Text>
      </div>
    </div>
  );
};
export default CurrencyXCurrency;
