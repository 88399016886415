import { FunctionComponent } from 'react';
import { MoonPayTheme } from 'theming/types';
import GlobalStyleProvider from '../../GlobalStyle';
import getTheme from '../../theme';
import { ThemeConfigV1Base, ThemeConfigV1Response } from './types';

interface ThemeProviderProps {
  themeConfig: ThemeConfigV1Response | ThemeConfigV1Base;
  defaultColorCode?: string;
}

const getMappedTheme = (
  mode: 'light' | 'dark',
  themeConfig: ThemeConfigV1Base | ThemeConfigV1Response,
) => {
  const { config } = themeConfig.themeConfig;

  const iconUrl =
    config?.[mode]?.iconUrl || themeConfig?.accountIconUrl || null;

  return {
    // Accents
    accentPrimary: config[mode].accentPrimary,
    accentSecondary: config[mode].accentSecondary,
    accentTertiary: config[mode].accentTertiary,
    accentQuaternary: config[mode].accentQuaternary,
    accentLabelPrimary: config[mode].accentLabelPrimary,
    accentLabelSecondary: config[mode].accentLabelSecondary,
    accentLabelTertiary: config[mode].accentLabelTertiary,
    accentLabelQuaternary: config[mode].accentLabelQuaternary,

    // Greys
    grey1: config[mode].grey1,
    grey2: config[mode].grey2,
    grey3: config[mode].grey3,
    grey4: config[mode].grey4,
    grey5: config[mode].grey5,
    grey6: config[mode].grey6,

    // System Backgrounds
    sysBackgroundPrimary: config[mode].sysBackgroundPrimary,
    sysBackgroundSecondary: config[mode].sysBackgroundSecondary,

    // Grouped Backgrounds
    groupBackgroundPrimary: config[mode].groupBackgroundPrimary,
    groupBackgroundSecondary: config[mode].groupBackgroundSecondary,

    // Labels - Typography
    labelPrimary: config[mode].labelPrimary,
    labelSecondary: config[mode].labelSecondary,
    labelTertiary: config[mode].labelTertiary,
    labelQuaternary: config[mode].labelQuaternary,

    // Buttons
    buttonPrimary: config[mode].buttonPrimary,
    buttonLabel: config[mode].buttonLabel,
    buttonRadius: config[mode].buttonRadius,

    // Border color
    borderColor: config[mode].borderColor,

    // Foreground
    foregroundRadius: config[mode].foregroundRadius,

    // Widget
    widgetRadius: config[mode].widgetRadius,
    widgetElevation: config[mode].widgetElevation,

    // Divider
    dividerNonOpaque: config[mode].dividerNonOpaque,
    dividerOpaque: config[mode].dividerOpaque,

    // Fill
    fillPrimary: config[mode].fillPrimary,
    fillSecondary: config[mode].fillSecondary,
    fillTertiary: config[mode].fillTertiary,
    fillQuaternary: config[mode].fillQuaternary,

    // Accent Fill
    accentFillPrimary: config[mode].accentFillPrimary,
    accentFillSecondary: config[mode].accentFillSecondary,
    accentFillTertiary: config[mode].accentFillTertiary,
    accentFillQuaternary: config[mode].accentFillQuaternary,

    // Partner icon
    iconUrl,
  };
};

const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({
  themeConfig,
  children,
  defaultColorCode,
}) => {
  const mappedVariables = {
    light: {
      ...getMappedTheme('light', themeConfig),
    },
    dark: {
      ...getMappedTheme('dark', themeConfig),
    },
  };

  const widgetTheme: MoonPayTheme = {
    ...getTheme(defaultColorCode),
    ...mappedVariables,
  };

  return (
    <GlobalStyleProvider theme={widgetTheme}>{children}</GlobalStyleProvider>
  );
};

export default ThemeProvider;
