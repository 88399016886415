import { parseToRgb } from 'polished';
import getColorCode from './getColorCode';

export interface MoonPayThemeBase {
  colors: {
    lightThemePrimary: string;
    darkThemePrimary: string;
  };
  utils: {
    hexToRgba: (hex: string, alpha: number) => string;
  };
}

const hexToRgba = (hex: string, alpha: number) => {
  const { red, green, blue } = parseToRgb(hex);
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

export const getCssVarValue = (cssVariable: string) =>
  window?.getComputedStyle(document.body)?.getPropertyValue(cssVariable);

export const isValidColorHex = (hex: string) =>
  /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(hex);

const getDefaultTheme = (defaultColorCode?: string): MoonPayThemeBase => ({
  colors: {
    ...getColorCode(defaultColorCode),
  },
  utils: {
    hexToRgba,
  },
});

export default getDefaultTheme;
