import { FC } from 'react';
import styled from 'styled-components';
import Icon from '../Icon';
import MoonpayStaticLogoWithoutText from '../Logo';

interface PartnerXMoonPayDisplayProps {
  partnerIconUrl: string;
  partnerName: string;
}

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Dot = styled.span<{ opacity: number }>`
  background: var(--accent-primary);
  height: 0.25rem;
  width: 0.25rem;
  margin: 0 0.25rem;
  border-radius: 50%;
  opacity: ${({ opacity }) => opacity / 100};
`;

const PartnerXMoonPayDisplay: FC<PartnerXMoonPayDisplayProps> = ({
  partnerIconUrl,
  partnerName,
}) => {
  return (
    <div>
      <div className="flex items-center justify-center">
        <DotsContainer>
          <Dot opacity={10} />
          <Dot opacity={20} />
          <Dot opacity={30} />
        </DotsContainer>
        <div className="rounded-logo mx-1 border border-divider-nonOpaque">
          <Icon
            icon={partnerIconUrl}
            iconAlt={partnerName}
            className="w-16 h-16 rounded-logo"
          />
        </div>
        <DotsContainer>
          <Dot opacity={30} />
          <Dot opacity={30} />
        </DotsContainer>
        <MoonpayStaticLogoWithoutText className="rounded-logo w-16 h-16 mx-1" />
        <DotsContainer>
          <Dot opacity={30} />
          <Dot opacity={20} />
          <Dot opacity={10} />
        </DotsContainer>
      </div>
    </div>
  );
};

export default PartnerXMoonPayDisplay;
