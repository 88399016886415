import styled from 'styled-components';

type RadioStyledProps = {
  checked: boolean;
  disabled?: boolean;
};

export const RadioStyled = styled.div<RadioStyledProps>`
  background: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.disabled
      ? 'var(--fill-secondary)'
      : props.checked
      ? 'var(--accent-fill-primary)'
      : 'transparent'};
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: ${(props) =>
      // eslint-disable-next-line no-nested-ternary
      props.checked && props.disabled
        ? 'var(--fill-secondary)'
        : props.checked
        ? 'var(--accent-primary)'
        : 'transparent'};
    width: ${(props) => (props.checked ? '0.375rem' : '0')};
    height: ${(props) => (props.checked ? '0.375rem' : '0')};
    transform: translate(-50%, -50%);
    border-radius: 50%;
    transition: 0.3s;
  }
`;
