import { FunctionComponent } from 'react';
import styled from 'styled-components';

export const Icon = styled.svg`
  fill: none;
  stroke: currentColor;
  stroke-width: 2px;
`;

type IconProps = {
  disabled?: boolean;
};

const CheckboxIcon: FunctionComponent<IconProps> = ({ disabled = false }) => {
  return (
    <Icon
      className={disabled ? 'text-fill-secondary' : 'text-label-primary'}
      viewBox="0 0 24 24"
    >
      <polyline points="20 6 9 17 4 12" />
    </Icon>
  );
};

export default CheckboxIcon;
