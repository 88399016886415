import { useRef } from 'react';

/**
 * Simple hook that logs to console every time function component is called
 * (rendered) with a counter.
 *
 * @param name Name or identifier to log.
 * @param delay Delay after which to log the count and reset it.
 */
const useRenderCount = (name: string, delay = 100): void => {
  const logTimeout = useRef<ReturnType<typeof setTimeout>>();
  const count = useRef(0);
  count.current += 1;

  clearTimeout((logTimeout.current as unknown) as number);
  logTimeout.current = setTimeout(() => {
    console.log('render', name, count.current);
    count.current = 0;
  }, delay);
};

export default useRenderCount;
