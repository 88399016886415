import React from 'react';

// useDeferredUserResponse is a hook that returns a promise that can be resolved
// from outside the component. This is useful for prompting the user for a
// response and then waiting for the user to respond.
export const useDeferredUserResponse = () => {
  // used to force a new deferred promise to be generated when the counter changes
  const [counter, setCounter] = React.useState(0);

  // create a promise that exposes a resolve and reject function
  // so that we can resolve the promise from outside the component
  const generateDeferredPromise = (): {
    promise: Promise<boolean>;
    resolve: (value: boolean) => void;
    reject: (value: boolean) => void;
  } => {
    let resolve: any, reject: any;
    const promise: Promise<boolean> = new Promise((res, rej) => {
      [resolve, reject] = [res, rej];
    });
    return { promise, reject, resolve };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let { promise, resolve } = React.useMemo(generateDeferredPromise, [counter]);

  // forces a new deferred promise to be generated when the counter changes
  const refresh = () => setCounter(counter + 1);

  return {
    deferredUserActionPromise: promise,
    accept: () => resolve(true),
    deny: () => resolve(false),
    refresh,
  };
};
