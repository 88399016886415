import { FunctionComponent } from 'react';
import ColorSchemeContext from 'src/contexts/ColorScheme/ColorSchemeContext';
import useColorSchemeSetup from 'src/hooks/useColorSchemeSetup';

const ColorSchemeProvider: FunctionComponent = ({ children }) => {
  const { value, setColorScheme, setFollowSystemPreference } =
    useColorSchemeSetup();

  return (
    <ColorSchemeContext.Provider
      value={{
        value,
        setColorScheme,
        setFollowSystemPreference,
      }}
    >
      {children}
    </ColorSchemeContext.Provider>
  );
};

export default ColorSchemeProvider;
