import { Button, Container, Icon, Text } from '@moonpay-widget/ui-kit';
import clsx from 'clsx';
import { ChangeEvent, FunctionComponent, ReactNode, useState } from 'react';
import PiiConsent from 'src/components/AccountConsent/PiiConsent';
import TermsOfUse from 'src/components/AccountConsent/TermsOfUse';
import {
  AccountTermsPrivacyDetails,
  CustomerAccountConnection,
} from 'src/wallet/walletProvider/kms/kmsApi';
import { TopBannerProps } from './TopBanner';

type MidSectionProps = {
  accountName: string;
  appName?: string;
  accountLogo?: string | ReactNode;
  title: string;
  message?: string;
  children: ReactNode;
};

const MidSection: FunctionComponent<MidSectionProps> = ({
  accountLogo,
  accountName,
  title,
  appName,
  message,
  children,
}) => (
  <div className="mt-4 mb-2 justify-top px-4">
    {accountLogo && (
      <div className="flex flex-row items-center justify-center text-center m-4">
        <Icon
          icon={accountLogo as string}
          iconAlt={accountName}
          className="w-16 h-16 rounded-xl"
        />
      </div>
    )}
    <div>
      <p className="text-center font-bold text-title1 leading-title1 font-stretch-condensed mt-4 mb-6">
        {`${title} ${appName || ''}`}
      </p>

      <Text
        variant="footnote"
        className="text-label-secondary mt-2 text-center"
      >
        {message}
      </Text>
    </div>

    <div className="min-h-64 justify-top">{children}</div>
  </div>
);

type BottomSectionProps = {
  aboveButtonsText?: string;
  approveButtonText?: string;
  cancelButtonText?: string;
  cancelDisabled?: boolean;
  approvalDisabled?: boolean;
  approvalHidden?: boolean;
  onCancelClicked: () => void;
  onApproveClicked: () => void;
  noModal?: boolean;
  account?: AccountTermsPrivacyDetails;
  connection?: CustomerAccountConnection;
};

const BottomSection: FunctionComponent<BottomSectionProps> = ({
  onCancelClicked,
  onApproveClicked,
  approveButtonText = 'Approve',
  cancelButtonText = 'Cancel',
  cancelDisabled = false,
  aboveButtonsText,
  approvalDisabled = false,
  approvalHidden = false,
  noModal = false,
  account,
  connection,
}) => {
  const onApprove = (event: React.MouseEvent<HTMLButtonElement>) => {
    onApproveClicked();
    event.preventDefault();
  };
  const onCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    onCancelClicked();
    event.preventDefault();
  };

  const [currApprovalDisabled, setCurrApprovalDisabled] =
    useState<boolean>(approvalDisabled);

  const toggleApproval = (value: boolean) => {
    if (value) {
      setCurrApprovalDisabled(false);
    } else {
      setCurrApprovalDisabled(true);
    }
  };

  const renderTos =
    (account?.privacyLink && !connection?.privacyPolicyAccepted) ||
    (account?.termsLink && !connection?.tosAccepted);

  return (
    <div
      className={clsx(
        'flex dialog-actions flex-col items-center text-center shadow-lg',
        !noModal && 'mb-6',
      )}
    >
      {account && connection && (
        <div className="m-6 dialog-actions flex-row items-center text-left justify-between pt-6">
          {renderTos && (
            <TermsOfUse
              className="mt-2"
              account={account}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                toggleApproval(event.target.checked);
              }}
            />
          )}
          <PiiConsent
            className="mt-2"
            account={account}
            connection={connection}
          />
        </div>
      )}
      <div className="flex dialog-actions flex-row items-center text-center justify-between pt-4 px-2">
        <Text variant="footnote" className="text-label-secondary">
          {aboveButtonsText}
        </Text>
      </div>
      <div className="flex flex-col-reverse dialog-actions sm:flex-row items-center justify-between px-4 pb-4 w-full">
        {!cancelDisabled && (
          <Button
            variant="custom"
            className="mx-2 border bg-system-background-secondary border-none bold"
            margin="small"
            onClick={onCancel}
          >
            <Text variant="headline" condensed className="text-label-primary">
              {cancelButtonText}
            </Text>
          </Button>
        )}
        {!approvalHidden && (
          <Button
            variant="custom"
            className="mx-2"
            margin="small"
            onClick={onApprove}
            disabled={currApprovalDisabled}
            style={{
              backgroundColor: currApprovalDisabled ? '#E5E5E5' : '#7D00FF',
              color: currApprovalDisabled ? '#4F4F4F' : 'white',
            }}
          >
            <Text variant="headline" condensed>
              {approveButtonText}
            </Text>
          </Button>
        )}
      </div>
    </div>
  );
};

type SecureWalletContainerProps = TopBannerProps &
  MidSectionProps &
  BottomSectionProps & {
    fitToContent?: boolean;
    noModal?: boolean;
  };

const SecureWalletContainer: FunctionComponent<SecureWalletContainerProps> = (
  props,
) => {
  return (
    <Container
      component="div"
      padding="0"
      showFooter={props.noModal ? false : true}
      fitFull={props.noModal}
      style={{
        marginTop: props.noModal ? '0vh' : '6vh',
        minHeight: '0px',
      }}
      className={clsx(
        '!rounded-[24px] text-label-primary bg-system-background-primary',
        props.noModal && 'h-full',
      )}
    >
      {props.fitToContent ? (
        <>
          {props.children}
          <BottomSection {...props} />
        </>
      ) : (
        <>
          <MidSection {...props} />
          <BottomSection {...props} />
        </>
      )}
    </Container>
  );
};

export default SecureWalletContainer;
