const environment = {
  moonpayDhwClientId: process.env.REACT_APP_MOONPAY_DHW_CLIENT_ID,

  // RPC providers URLs
  ethereumMainnetRpcProviderUrl:
    process.env.REACT_APP_ETHEREUM_MAINNET_RPC_PROVIDER_URL ||
    'https://eth.llamarpc.com',
  ethereumGoerliRpcProviderUrl:
    process.env.REACT_APP_ETHEREUM_GOERLI_RPC_PROVIDER_URL ||
    'https://ethereum-goerli.publicnode.com',
  polygonMainnetRpcProviderUrl:
    process.env.REACT_APP_POLYGON_MAINNET_RPC_PROVIDER_URL ||
    'https://polygon-rpc.com',
  polygonTestnetRpcProviderUrl:
    process.env.REACT_APP_POLYGON_TESTNET_RPC_PROVIDER_URL ||
    'https://rpc-mumbai.maticvigil.com',
};

export default environment;
