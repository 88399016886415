import { AllHTMLAttributes, ComponentProps, ReactNode } from 'react';
import { Link } from 'react-router-dom';

export type LinkElementProps = ComponentProps<typeof Link>;
export type AnchorElementProps = ComponentProps<'a'>;
export type ButtonElementProps = ComponentProps<'button'>;

export type ButtonMargin = 'small' | 'none' | undefined;

export type ButtonRadius = 'small' | undefined;

export type ButtonVariant =
  | 'primary'
  | 'text'
  | 'outline'
  | 'danger'
  | 'custom'
  | 'clear';

export interface ButtonProps extends AllHTMLAttributes<HTMLElement> {
  loading?: boolean;
  margin?: ButtonMargin;
  radius?: ButtonRadius;
  href?: string;
  to?: LinkElementProps['to'];
  variant?: ButtonVariant;
  /**
   * Icon that will be rendered at the far right side of the button.
   * Possible cases:
   * 1. render arrow icon
   *    icon={undefined} and variant={primary|danger}
   * 2. hides icon completely
   *    icon={null}
   * 3. custom icon
   *    icon={<MyCustomIcon />}
   */
  disabled?: boolean;
  icon?: ReactNode;
  hasArrowRightIcon?: boolean;
}

export function isAnchorElement(
  commonProps: object,
): commonProps is AnchorElementProps {
  return 'href' in commonProps && !!commonProps.href;
}

export function isLinkElement(
  commonProps: object,
): commonProps is LinkElementProps {
  return 'to' in commonProps && !!commonProps.to;
}
