import styled from 'styled-components';
import moonpayDarkIllustration from './assets/moonpay-dark-illustration.svg';
import moonpayLightIllustration from './assets/moonpay-light-illustration.svg';

const LogoBackground = styled.div<{ isDarkMode?: boolean }>`
  background-image: ${(props) =>
    props.isDarkMode
      ? `url(${moonpayDarkIllustration})`
      : `url(${moonpayLightIllustration})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -10;
`;

export default LogoBackground;
