import clsx from 'clsx';
import { FC } from 'react';
import ContainerFooter from '../ContainerFooter';

import { ContainerProps } from './types';

const Container: FC<ContainerProps> = ({
  component: Component = 'div',
  children,
  className,
  overlay = false,
  widget = false,
  modal = false,
  fullScreen = false,
  showFooter = false,
  fitFull = false,
  padding = clsx('p-4 sm:p-8', showFooter && 'sm:pb-16'),
  footerVariant,
  ...props
}) => {
  let cls = '';

  if (widget) {
    cls =
      'w-full flex-1 sm:min-w-sm lg:px-8 flex sm:items-center sm:justify-center';
  } else if (overlay) {
    cls = `absolute inset-0 z-30 min-h-full sm:max-w-sm mx-auto ${padding} sm:rounded-widget bg-system-background-primary flex flex-col`;
  } else if (modal) {
    cls = `absolute inset-0 z-30 min-h-full sm:max-w-sm mx-auto pt-3 sm:pt-6 ${padding} sm:rounded-t-widget bg-system-background-primary flex flex-col`;
  } else if (fullScreen) {
    cls =
      'flex-1 sm:flex-initial sm:max-w-sm min-h-widget mx-auto flex flex-col justify-between bg-system-background-primary sm:shadow-widget sm:rounded-widget overflow-hidden sm:my-4 lg:my-8';
  } else if (fitFull) {
    cls =
      'flex-1 flex-initial min-h-widget mx-auto flex flex-col justify-between bg-system-background-primary overflow-hidden';
  } else {
    cls = `flex-1 sm:flex-initial relative w-full sm:max-w-sm sm:min-h-widget mx-auto ${padding} flex flex-col justify-between sm:shadow-widget sm:rounded-widget overflow-hidden sm:my-4 lg:my-8 bg-system-background-primary`;
  }
  return (
    <Component className={clsx(className, cls)} {...props}>
      {children}
      {showFooter && !widget && !modal && (
        <ContainerFooter variant={footerVariant} />
      )}
    </Component>
  );
};

export default Container;
export type { ContainerProps };
