import clsx from 'clsx';
import {
  ChangeEvent,
  ClipboardEvent,
  FC,
  FocusEvent,
  KeyboardEvent,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { getVerificationCodeFromClipboard } from './helpers';

interface SafariMobileInputProps {
  length: number;
  onChange?: (verificationCode: string) => void;
  className?: string;
  maxColumnSize?: number; // Maximum column size used in the characters grid
  inputClassName?: string;
}

const Container = styled.div<{
  columns: number;
  maxColumnSize?: number;
}>`
  height: 4rem;

  display: grid;
  grid-template-columns: ${(props) =>
    `repeat(${props.columns}, minmax(2rem, ${props.maxColumnSize ?? 4}rem))`};
  grid-template-rows: 4rem;
  gap: 0.5rem;
  justify-content: center;
`;

const SafariCharacterInput = styled.input`
  outline: none;
  text-align: center;

  height: 100%;
  width: 100%;
  transition: border-color 0.2s;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const SafariMobileInput: FC<SafariMobileInputProps> = ({
  length,
  onChange,
  className,
  maxColumnSize,
  inputClassName,
}) => {
  const inputsRef = useRef<HTMLInputElement[]>([]);
  const [characters, setCharacters] = useState<string[]>([]);

  const updateCharacters = (newCharacter: string, characterIndex: number) => {
    const prevCharactersLength = characters.join('').length;
    const newCharacters = [...characters];
    newCharacters[characterIndex] = newCharacter;
    const newCharactersLength = newCharacters.join('').length;

    setCharacters(newCharacters);

    if (newCharactersLength === length) {
      onChange?.(newCharacters.join(''));
      inputsRef.current.forEach((input) => input.blur());
    }

    // Should trigger only once,
    // when the customer starts backspacing or deleting
    if (
      prevCharactersLength === length &&
      newCharactersLength < prevCharactersLength
    ) {
      onChange?.('');
    }
  };

  const focusNextCharacter = (nextCharacterIndex: number): void => {
    if (nextCharacterIndex < 0 || nextCharacterIndex > length - 1) {
      return;
    }

    inputsRef.current[nextCharacterIndex].focus();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): boolean => {
    const input = event.target as HTMLInputElement;
    const characterIndex = parseInt(input.name, 10);
    const isBackspaceOrDelete =
      event.key === 'Backspace' || event.key === 'Delete';
    const isEmptyString = !input.value;

    if (!isBackspaceOrDelete || !isEmptyString) {
      return true;
    }

    // This is a case where the customer focused empty field,
    // and starts pressing backspace or delete.
    // onChange won't be triggered so we gotta listen to onKeyDown explicitly
    const nextCharacterIndex = characterIndex - 1;

    updateCharacters('', nextCharacterIndex);
    focusNextCharacter(nextCharacterIndex);

    return true;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): boolean => {
    const characterIndex = parseInt(e.target.name, 10);
    const prevCharacter = characters[characterIndex];
    const targetValue = e.target.value ?? '';

    // Rare edge case where input gets 2 number,
    // so we'll just take the last one
    const newCharacter =
      targetValue.length > 1
        ? targetValue[targetValue.length - 1]
        : targetValue;

    // If it's something other than digit or empty string, prevent the change
    if (!/^[0-9]$/.test(newCharacter) && newCharacter) {
      return false;
    }

    // Case 1: Focused filed has char and pressed delete or backspace
    // Behavior: Delete the character but leave the focus
    if (prevCharacter && !newCharacter) {
      updateCharacters('', characterIndex);
      return true;
    }

    // Case 2: Focused filed doesn't have char and pressed delete or backspace
    // Behavior: Delete the previous character and keep focus previous
    if (!prevCharacter && !newCharacter) {
      updateCharacters('', characterIndex - 1);
      focusNextCharacter(characterIndex - 1);
      return true;
    }

    // Case 3: Focused filed has char and customer typed new character
    // Behavior: Replace the old char with the new one, and focus next field
    if (prevCharacter && newCharacter) {
      updateCharacters(newCharacter, characterIndex);
      focusNextCharacter(characterIndex + 1);
      return true;
    }

    // Case 4: Focused filed doesn't have char and customer typed new character
    // Behavior: Update the focused filled and focus next field
    if (!prevCharacter && newCharacter) {
      updateCharacters(newCharacter, characterIndex);
      focusNextCharacter(characterIndex + 1);
      return true;
    }

    return true;
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const verificationCode = getVerificationCodeFromClipboard(event, length);

    const prevCharactersLength = characters.join('').length;

    const newCharacters = verificationCode.split('');
    const newCharactersLength = verificationCode.length;

    // Take only first {length} characters
    setCharacters(newCharacters);

    if (newCharactersLength === length) {
      onChange?.(newCharacters.join(''));
      inputsRef.current.forEach((input) => input.blur());
    }

    // Should trigger only once,
    // when the customer starts backspacing or deleting
    if (
      prevCharactersLength === length &&
      newCharactersLength < prevCharactersLength
    ) {
      onChange?.('');
    }
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  // Generates [0, 1, 2, ..., length - 1]
  const inputs = Array.from(Array(length).keys());

  return (
    <Container
      className={className}
      columns={length}
      maxColumnSize={maxColumnSize}
    >
      {inputs.map((characterIndex) => (
        <SafariCharacterInput
          key={`character-${characterIndex}`}
          data-testid={`SafariCharacterInput-${characterIndex}`}
          ref={(el) => {
            inputsRef.current[characterIndex] = el as HTMLInputElement;
          }}
          name={characterIndex.toString()}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          onFocus={handleFocus}
          type="tel"
          autoComplete="off"
          value={characters?.[characterIndex] ?? ''}
          pattern="[0-9]{1}"
          className={clsx(
            'bg-system-background-secondary rounded-foreground focus:border focus:border-accent-primary',
            'text-label-primary text-title1 leading-title1 font-regular font-stretch-condensed',
            inputClassName,
          )}
        />
      ))}
    </Container>
  );
};

export default SafariMobileInput;
