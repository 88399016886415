import { keyframes } from 'styled-components';

const placeholderPulseAnimation = keyframes`
  0% {
    opacity: .6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .6;
  }
`;

export default placeholderPulseAnimation;
