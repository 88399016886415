import styled, { keyframes } from 'styled-components';
import { LoaderProps } from './types';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div<LoaderProps>`
  animation: ${({ time }) => time || 1}s ${spin} linear infinite;
  background: transparent;
  border: ${({ borderWidth }) => borderWidth || 2}px solid
    ${(props) => props.color};
  border-bottom-color: transparent;
  border-radius: 50%;
  margin: auto;
  height: 1.1875rem;
  width: 1.1875rem;
`;

export default Loader;
