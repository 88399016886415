import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import MiddleTruncate from './MiddleTruncate';

interface CopyTextFieldProps {
  text: string;
  onClick?: () => void;
  float?: string;
}

const CopyTextField: React.FunctionComponent<CopyTextFieldProps> = ({
  text,
  onClick,
  float = 'start',
}) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) {
      return;
    }
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 1000);
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout);
  }, [setCopied, copied]);

  return (
    <CopyToClipboard text={text}>
      <button
        type="button"
        className={`flex w-full justify-${float} leading-tight rounded-lg cursor-pointer`}
        onClick={() => {
          setCopied(true);
          if (onClick) {
            onClick();
          }
        }}
      >
        <MiddleTruncate className="float-right" text={text} />
      </button>
    </CopyToClipboard>
  );
};

export default CopyTextField;
