import styled from 'styled-components';
import Text from '../Text';
import { RadioGroupBoxedProps } from './RadioGroupBoxed';

const StyledRadioButton = styled.input.attrs({ type: 'radio' })`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--label-quaternary);
  appearance: none;
  background-color: transparent;
  position: relative;
  transition: border-color 0.075s ease; // This transition is applied in all states

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: background-color 0.075s ease, border-color 0.075s ease;
  }

  &:checked {
    border-color: var(--accent-primary);
  }

  &:checked:after {
    background-color: var(--accent-primary);
    border-color: var(--accent-primary);
  }

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--accent-primary);
  }
`;

interface RadioGroupBoxedRowProps<T = string> {
  value: RadioGroupBoxedProps<T>['options'][0]['value'];
  label: RadioGroupBoxedProps<T>['options'][0]['label'];
  onChange: RadioGroupBoxedProps<T>['onChange'];
  selected: boolean;
  name: string;
}

export function RadioGroupBoxedRow<T = string>({
  value,
  label,
  onChange,
  selected,
  name,
}: RadioGroupBoxedRowProps<T>) {
  const stringifiedValue = value.join(' and ');
  return (
    <label
      key={stringifiedValue}
      className="flex justify-between items-center py-4 gap-x-1"
    >
      <Text variant="callout" bold={selected}>
        {label}
      </Text>
      <span className="flex justify-center items-center">
        <StyledRadioButton
          type="radio"
          name={name}
          value={stringifiedValue}
          checked={selected}
          onChange={() => onChange(value)}
        />
      </span>
    </label>
  );
}
