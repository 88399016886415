import { Checkbox } from '@moonpay-widget/ui-kit';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { strings } from 'src/i18n';
import {
  AccountTermsPrivacyDetails,
  CustomerAccountConnection,
} from 'src/wallet/walletProvider/kms/kmsApi';

interface PiiConsentProps {
  className?: string;
  highlightColor?: string;
  account: AccountTermsPrivacyDetails;
  connection: CustomerAccountConnection;
}

const PiiConsent: FC<PiiConsentProps> = ({
  className,
  highlightColor = 'primary',
  account,
  connection,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(connection.piiConsent);

  return (
    <Checkbox
      checked={checked}
      className={className}
      id="policyAgreement"
      data-testid="LoginFormPolicyAgreement"
      name="policyAgreement"
      highlightColor={highlightColor}
      onChange={async (e) => {
        setChecked(!checked);
        localStorage.setItem('pii-consent', e.target.checked.toString());
      }}
      label={
        <div>
          <span>
            {t(strings.consents.pii.description, { accountName: account.name })}
          </span>
        </div>
      }
    />
  );
};

export default PiiConsent;
