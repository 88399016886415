import styled, { keyframes } from 'styled-components';

interface ProgressBarProps {
  activeSteps: number;
  totalSteps: number;
  variant?: 'success' | 'warning' | 'error' | 'primary';
  time?: number;
  onStepFinished?: (stepIndex: number) => void;
}

const lookup: Record<Required<ProgressBarProps>['variant'] | 'null', string> = {
  success: 'var(--accent-secondary)',
  warning: 'var(--accent-tertiary)',
  error: 'var(--accent-quaternary)',
  primary: 'var(--accent-primary)',
  null: 'var(--fill-secondary)',
};

const progressAnimation = keyframes`
  0%   { width: 0% }
  100% { width: 100% }
`;

const Step = styled.div<{
  variant?: ProgressBarProps['variant'];
  time?: ProgressBarProps['time'];
}>`
  height: 6px;
  width: 100%;
  background-color: ${(props) =>
    props.time ? lookup.null : lookup[props.variant || 'null']};
  border-radius: 2px;
  margin-right: 0.25rem;

  div {
    height: 6px;
    border-radius: 2px;
    background-color: ${(props) => lookup[props.variant || 'null']};
    animation: ${progressAnimation} ${(props) => props.time}s linear;
  }
`;

const ProgressBar = ({
  activeSteps,
  totalSteps,
  variant = 'success',
  time,
  onStepFinished,
}: ProgressBarProps) => {
  const remainingSteps = totalSteps - activeSteps;

  return (
    <div className="flex items-center">
      {[...Array(activeSteps)].map((value, index) => {
        const lastActiveStep = activeSteps - 1 === index;

        if (time && lastActiveStep) {
          return (
            <Step
              // eslint-disable-next-line react/no-array-index-key
              key={`active ${index}`}
              time={time}
              variant={variant}
              onAnimationEnd={() => onStepFinished && onStepFinished(index)}
            >
              <div />
            </Step>
          );
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Step key={`active ${index}`} variant={variant} />
        );
      })}
      {[...Array(remainingSteps)].map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Step key={`notActive ${index}`} />
      ))}
    </div>
  );
};

export default ProgressBar;
