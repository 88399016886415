import { darken, transparentize } from 'polished';
import { FC, ReactNode } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { MoonPayThemeBase } from 'theming/theme';
import { MoonPayTheme } from 'theming/types';

/**
 * See tailwind.config.js for more info about variables usage
 */
const GlobalStyle = createGlobalStyle<{ theme: MoonPayTheme }>`
  :root {
    --trustpilot-color: #00b67a;
  }


  body {
    // Callisto System - Colors

    // Light theme
    color-scheme: light;

    --moonpay-brand: #7d00ff;

    // Accents
    --accent-primary:  ${(props) => props.theme.light.accentPrimary};
    --accent-secondary: ${(props) => props.theme.light.accentSecondary};
    --accent-tertiary: ${(props) => props.theme.light.accentTertiary};
    --accent-quaternary: ${(props) => props.theme.light.accentQuaternary};
    --accent-label-primary:  ${(props) => props.theme.light.accentLabelPrimary};
    --accent-label-secondary: ${(props) =>
      props.theme.light.accentLabelSecondary};
    --accent-label-tertiary: ${(props) =>
      props.theme.light.accentLabelTertiary};
    --accent-label-quaternary: ${(props) =>
      props.theme.light.accentLabelQuaternary};

    // Greys
    --grey-1: ${(props) => props.theme.light.grey1};
    --grey-2: ${(props) => props.theme.light.grey2};
    --grey-3: ${(props) => props.theme.light.grey3};
    --grey-4: ${(props) => props.theme.light.grey4};
    --grey-5: ${(props) => props.theme.light.grey5};
    --grey-6: ${(props) => props.theme.light.grey6};

    // Border Color
    --border-color: ${(props) => props.theme.light.borderColor};
  
    //Foreground
    --foreground-radius: ${(props) => props.theme.light.foregroundRadius};

    // Widget
    --widget-radius: ${(props) => props.theme.light.widgetRadius};
    --widget-elevation: ${(props) => props.theme.light.widgetElevation};

    // System Backgrounds
    --sys-background-primary: ${(props) =>
      props.theme.light.sysBackgroundPrimary};
    --sys-background-secondary: ${(props) =>
      props.theme.light.sysBackgroundSecondary};

    // Grouped Backgrounds
    --group-background-primary: ${(props) =>
      props.theme.light.groupBackgroundPrimary};
    --group-background-secondary: ${(props) =>
      props.theme.light.groupBackgroundSecondary};

    // Labels - Typography
    --label-primary: ${(props) => props.theme.light.labelPrimary};
    --label-secondary :${(props) => props.theme.light.labelSecondary};
    --label-tertiary: ${(props) => props.theme.light.labelTertiary};
    --label-quaternary: ${(props) => props.theme.light.labelQuaternary};

    // Buttons
    --button-primary: ${(props) => props.theme.light.buttonPrimary};
    --button-hover: ${(props) => darken(0.1, props.theme.light.buttonPrimary)};
    --button-label: ${(props) => props.theme.light.buttonLabel};
    --button-radius: ${(props) => props.theme.light.buttonRadius};


    // Divider
    --divider-non-opaque: ${(props) => props.theme.light.dividerNonOpaque};
    --divider-opaque: ${(props) => props.theme.light.dividerOpaque};

    // Fill
    --fill-primary: ${(props) => props.theme.light.fillPrimary};
    --fill-secondary: ${(props) => props.theme.light.fillSecondary};
    --fill-tertiary: ${(props) => props.theme.light.fillTertiary};
    --fill-quaternary: ${(props) => props.theme.light.fillQuaternary};
    --fill-quaternary-hover: ${(props) =>
      darken(0.5, props.theme.light.fillQuaternary)};

    // Accent Fill
    --accent-fill-primary: ${(props) => props.theme.light.accentFillPrimary};
    --accent-fill-secondary: ${(props) =>
      props.theme.light.accentFillSecondary};
    --accent-fill-tertiary: ${(props) => props.theme.light.accentFillTertiary};
    --accent-fill-quaternary:${(props) =>
      props.theme.light.accentFillQuaternary};

    // Auto tint, Used depending on the background it sits on
    --auto-tint-primary: #000000;
    --auto-tint-secondary: #ffffff;
  }

  .dark {
    // Dark Theme
    color-scheme: dark;

    --moonpay-brand: #ffffff;

    // Accents
    --accent-primary:  ${(props) => props.theme.dark.accentPrimary};
    --accent-secondary: ${(props) => props.theme.dark.accentSecondary};
    --accent-tertiary: ${(props) => props.theme.dark.accentTertiary};
    --accent-quaternary: ${(props) => props.theme.dark.accentQuaternary};
    --accent-label-primary: ${(props) => props.theme.dark.accentLabelPrimary};
    --accent-label-secondary: ${(props) =>
      props.theme.dark.accentLabelSecondary};
    --accent-label-tertiary: ${(props) => props.theme.dark.accentLabelTertiary};
    --accent-label-quaternary: ${(props) =>
      props.theme.dark.accentLabelQuaternary};

    // Greys
    --grey-1: ${(props) => props.theme.dark.grey1};
    --grey-2: ${(props) => props.theme.dark.grey2};
    --grey-3: ${(props) => props.theme.dark.grey3};
    --grey-4: ${(props) => props.theme.dark.grey4};
    --grey-5: ${(props) => props.theme.dark.grey5};
    --grey-6: ${(props) => props.theme.dark.grey6};

    // Border Color
    --border-color: ${(props) => props.theme.dark.borderColor};

    //Foreground
    --foreground-radius: ${(props) => props.theme.dark.foregroundRadius};

    // Widget
    --widget-radius: ${(props) => props.theme.dark.widgetRadius};
    --widget-elevation: ${(props) => props.theme.dark.widgetElevation};

    // System Backgrounds
    --sys-background-primary: ${(props) =>
      props.theme.dark.sysBackgroundPrimary};
    --sys-background-secondary: ${(props) =>
      props.theme.dark.sysBackgroundSecondary};

    // Grouped Backgrounds
    --group-background-primary: ${(props) =>
      props.theme.dark.groupBackgroundPrimary};
    --group-background-secondary: ${(props) =>
      props.theme.dark.groupBackgroundSecondary};

    // Labels - Typography
    --label-primary: ${(props) => props.theme.dark.labelPrimary};
    --label-secondary :${(props) => props.theme.dark.labelSecondary};
    --label-tertiary: ${(props) => props.theme.dark.labelTertiary};
    --label-quaternary: ${(props) => props.theme.dark.labelQuaternary};

    // Buttons
    --button-primary: ${(props) => props.theme.dark.buttonPrimary};
    --button-hover: ${(props) => darken(0.1, props.theme.dark.buttonPrimary)};
    --button-label: ${(props) => props.theme.dark.buttonLabel};
    --button-radius: ${(props) => props.theme.dark.buttonRadius};


    // Divider
    --divider-non-opaque: ${(props) => props.theme.dark.dividerNonOpaque};
    --divider-opaque: ${(props) => props.theme.dark.dividerOpaque};

    // Fill
    --fill-primary: ${(props) => props.theme.dark.fillPrimary};
    --fill-secondary: ${(props) => props.theme.dark.fillSecondary};
    --fill-tertiary: ${(props) => props.theme.dark.fillTertiary};
    --fill-quaternary: ${(props) => props.theme.dark.fillQuaternary};
    --fill-quaternary-hover: ${(props) =>
      darken(0.5, props.theme.dark.fillQuaternary)};

    // Accent Fill
    --accent-fill-primary: ${(props) => props.theme.dark.accentFillPrimary};
    --accent-fill-secondary: ${(props) => props.theme.dark.accentFillSecondary};
    --accent-fill-tertiary: ${(props) => props.theme.dark.accentFillTertiary};
    --accent-fill-quaternary:${(props) =>
      props.theme.dark.accentFillQuaternary};

    // Auto tint, Used depending on the background it sits on
    --auto-tint-primary: #ffffff;
    --auto-tint-secondary: #000000;

    .shadow-outline,
    .focus\\:shadow-outline:focus,
    .focus-within\\:shadow-outline:focus-within {
      box-shadow: 0 0 0 3px ${(props): string =>
        transparentize(0.5, props.theme.colors.darkThemePrimary)};
    }

    .iti__selected-flag:focus {
      box-shadow: 0 0 0 3px ${(props): string =>
        transparentize(0.5, props.theme.colors.darkThemePrimary)};
    }
  }

  .shadow-outline,
  .focus\\:shadow-outline:focus,
  .focus-within\\:shadow-outline:focus-within {
    box-shadow: 0 0 0 3px ${(props): string =>
      transparentize(0.5, props.theme.colors.lightThemePrimary)};
  }

  .iti__selected-flag:focus {
    box-shadow: 0 0 0 3px ${(props): string =>
      transparentize(0.5, props.theme.colors.lightThemePrimary)};
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--sys-background-primary);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--grey-4);
    border-radius: 500px;
    border: 2px solid var(--sys-background-primary);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--grey-3);
  }

  .font-stretch-condensed {
    // Font stretch of 60% and weight of 890
    font-variation-settings: "wdth" 60, 'wght' 890;
    // The below will apply if the font does not support font-variation-settings
    // which means it is not a variable font
    font-stretch: 60%;
    font-weight: 890;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

interface GlobalStyleProviderProps {
  theme?: MoonPayTheme | MoonPayThemeBase;
  children: ReactNode;
}

const GlobalStyleProvider: FC<GlobalStyleProviderProps> = ({
  theme,
  children,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default GlobalStyleProvider;
