import { Text } from '@moonpay-widget/ui-kit';
import MoonPayIcon from '@moonpay-widget/ui-kit/src/ContainerFooter/Logo';
import { FC } from 'react';
import CopyTextField from 'src/components/WalletAddressComponent/CopyTextField';

interface TransactionParticipantsInfoProps {
  leftLabel: string;
  leftValue: string;
  rightLabel: string;
  rightValue: string;
}

const formatWalletAddress = (walletAddress: string) => {
  // First test the address is an ETH address and not something like an ENS domain
  const isENSDomain =
    /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)$/.test(
      walletAddress,
    );

  if (!isENSDomain) {
    // Then split the first 8 and last 4 characters out
    const match = walletAddress.match(/(\w{6}).+(\w{4})/);
    return match ? `${match[1]}…${match[2]}` : walletAddress;
  }

  return walletAddress;
};

const TransactionParticipantsInfo: FC<TransactionParticipantsInfoProps> = ({
  leftLabel,
  leftValue,
  rightLabel,
  rightValue,
}) => {
  return (
    <div className="flex justify-between bg-system-background-secondary p-4 rounded-xxl mb-3">
      <div className="float-left w-1/2">
        <Text variant="footnote" as="div" className="text-label-secondary">
          {leftLabel}
        </Text>

        <div className="flex items-center text-left mt-1">
          {leftValue ? (
            <Text variant="callout" className="font-light">
              {formatWalletAddress(leftValue)}
            </Text>
          ) : (
            <>
              <MoonPayIcon
                style={{
                  color: 'var(--moonpay-brand)',
                }}
                className="mr-2"
              />
              <Text variant="callout" className="font-light">
                MoonPay Account
              </Text>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col w-1/2 justify-center items-end text-right">
        <Text variant="footnote" as="div" className="text-label-secondary">
          {rightLabel}
        </Text>
        <div className="flex self-end justify-end w-36 mt-1 font-light text-callout leading-callout tracking-callout">
          <CopyTextField float="end" text={rightValue} />
        </div>
      </div>
    </div>
  );
};

export default TransactionParticipantsInfo;
