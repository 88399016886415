import styled from 'styled-components';

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--accent-fill-primary);
`;

export const ImagePlaceholder = styled(Placeholder)`
  height: var(--image-size);
  width: var(--image-size);
  background: var(--accent-fill-primary);
`;

export default Placeholder;
