import { Checkbox } from '@moonpay-widget/ui-kit';
import { ComponentProps, FC, useState } from 'react';
import { AccountTermsPrivacyDetails } from 'src/wallet/walletProvider/kms/kmsApi';

interface TermsOfUseProps {
  onChange: ComponentProps<typeof Checkbox>['onChange'];
  className?: string;
  highlightColor?: string;
  account: AccountTermsPrivacyDetails;
}

const TermsOfUse: FC<TermsOfUseProps> = ({
  className,
  onChange,
  highlightColor = 'primary',
  account,
}) => {
  const [checked, setChecked] = useState<boolean>(false);

  return (
    <Checkbox
      checked={checked}
      className={className}
      id="policyAgreement"
      data-testid="LoginFormPolicyAgreement"
      name="policyAgreement"
      highlightColor={highlightColor}
      onChange={(e) => {
        setChecked(!checked);
        onChange(e);
      }}
      label={
        <div>
          <span>I agree with {account.name}'s </span>
          {account.termsLink && (
            <span>
              <a
                className={`text-footnote leading-footnote tracking-footnote font-semibold text-accent-${highlightColor} hover:underline focus:underline`}
                href={account.termsLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                Terms of Use
              </a>
              {account.privacyLink ? <span> and </span> : <span>.</span>}
            </span>
          )}
          {account.privacyLink && (
            <a
              className={`text-footnote leading-footnote tracking-footnote font-semibold text-accent-${highlightColor} hover:underline focus:underline`}
              href={account.privacyLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy.
            </a>
          )}
        </div>
      }
    />
  );
};

export default TermsOfUse;
