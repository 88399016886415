import MoonpayStaticLogoWithoutText from '@moonpay-widget/ui-kit/src/Logo';
import { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react';
import {
  PromptState,
  PromptUserFunc,
} from 'src/components/PromptContainer/types';
import SecureWalletContainer from 'src/components/SecureWalletContainer';
import ColorSchemeProvider from 'src/contexts/ColorScheme/ColorSchemeProvider';
import { useDeferredUserResponse } from './useDeferredUserResponse';

export const PromptContainer = forwardRef(
  (props: any, ref: ForwardedRef<any>): any => {
    const [promptState, setPromptState] = useState<PromptState | null>(null);

    const { deferredUserActionPromise, accept, deny, refresh } =
      useDeferredUserResponse();

    const promptUser: PromptUserFunc = async (targetState: PromptState) => {
      setPromptState(targetState); // show the intended prompt
      const userResponse = await deferredUserActionPromise;
      refresh();
      setPromptState({
        ...targetState,
        component: <></>,
      });
      let resp = await userResponse; // wait for user response
      setPromptState(null); // then clear prompt
      return resp; // return user response
    };

    useImperativeHandle(ref, () => ({
      promptUser,
    }));

    if (!promptState) return null;
    return (
      <ColorSchemeProvider>
        <SecureWalletContainer
          onCancelClicked={deny}
          title={promptState.title}
          message={promptState.message}
          accountName={promptState.accountName || 'Moonpay'}
          onApproveClicked={accept}
          url={promptState.url}
          accountLogo={promptState.accountLogo || MoonpayStaticLogoWithoutText}
          approveButtonText={promptState.approveButtonText}
          cancelButtonText={promptState.cancelButtonText}
          aboveButtonsText={promptState.aboveButtonsText}
          approvalDisabled={promptState.approvalDisabled}
          approvalHidden={promptState.approvalHidden}
          fitToContent={promptState.fitToContent}
          noModal={promptState.noModal}
          cancelDisabled={promptState.cancelButtonDisabled}
          account={promptState.account}
          connection={promptState.connection}
        >
          {promptState.component}
        </SecureWalletContainer>
      </ColorSchemeProvider>
    );
  },
);
