import styled from 'styled-components';

export const HiddenCheckbox = styled.input`
  border: 0;
  clip-path: inset(50%);
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
