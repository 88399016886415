import clsx from 'clsx';
import { FC } from 'react';
import styled from 'styled-components';
import Text from '../Text';
import { SegmentedControlProps } from './types';

const SegmentedControlContainer = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 1000rem;
  height: 3rem;
`;

const SegmentedControlButtonContainer = styled.li`
  height: 100%;
`;

const SegmentedControlButton = styled.button`
  border-radius: 1000rem;
  height: 100%;
  width: 100%;
  padding: 0 0.75rem;
  white-space: nowrap;
  display: inline-block;
`;

const SegmentedControl: FC<SegmentedControlProps> = ({
  values,
  onChange,
  selectedValue,
  className,
}) => {
  return (
    <SegmentedControlContainer
      className={clsx('bg-system-background-secondary p-1', className)}
    >
      {values.map((segmentedControlValue) => (
        <SegmentedControlButtonContainer key={segmentedControlValue.value}>
          <SegmentedControlButton
            type="button"
            data-testid={segmentedControlValue.value}
            onClick={() => onChange(segmentedControlValue)}
            className={clsx(
              'flex-1 text-center transition-colors',
              selectedValue === segmentedControlValue.value
                ? 'bg-btn-primary text-btn-label'
                : 'text-label-tertiary',
            )}
          >
            <Text variant="footnote" as="span" className="block">
              {segmentedControlValue.label}
            </Text>
          </SegmentedControlButton>
        </SegmentedControlButtonContainer>
      ))}
    </SegmentedControlContainer>
  );
};

export default SegmentedControl;
export type { SegmentedControlProps };
