import { ChevronDownIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import HelperText from '../HelperText';
import Label from '../Label';
import Text from '../Text';
import TextField from '../TextField';
import {
  CurrencySelectorProps,
  CurrencySelectorWitAutoswitchProps,
} from './types';

const parseCurrencyCode = (code: string) => {
  return code.split('_')[1];
};

const CurrencyIcon = styled.span<{ icon: string }>`
  background: url(${({ icon }) => icon});
  background-size: 1.25rem;
  background-repeat: no-repeat;
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.25rem;
`;

const SelectorButton = styled.button<
  Pick<CurrencySelectorProps, 'code' | 'icon'>
>`
  position: relative;
  display: flex;
  align-items: center;
  align-self: flex-end;
  min-width: 5rem;
  height: 2.25rem;
  border-radius: 10rem;
  text-transform: uppercase;
  transition: 0.3s ease-out background-color;
  background-color: var(--fill-quaternary);

  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
  }

  &:hover:enabled {
    background-color: var(--fill-secondary);
  }

  ${({ code }) =>
    code === 'Select ..' &&
    `
    p {
      margin-right: 2rem;
    }
    text-transform: none;
    padding-left: 1.05rem;
    padding-right: 0.5rem;
  `}

  ${({ code }) =>
    parseCurrencyCode(code) &&
    `
  &:after {
    background-color: #dcdcde;
    border-radius: 50%;
    background-size: 0.8rem;
    background-repeat: no-repeat;
    content: '${parseCurrencyCode(code).slice(0, 1)}';
    font-size: 0.6rem;
    height: 0.8rem;
    left: 1.2rem;
    bottom: 0.1rem;
    position: absolute;
    width: 0.8rem;
  }`}
`;

const TextFieldContainer = styled.div`
  width: calc(100% - 11rem);
`;

const CurrencySelector: FunctionComponent<CurrencySelectorProps> = ({
  borderClassNames,
  code,
  description,
  disabled,
  disabledSelector,
  errorText,
  helperText,
  icon,
  inputHeight = 'py-4',
  inputHelperText,
  isFormik,
  label,
  name,
  onBlur,
  onChange,
  onClick,
  placeholder,
  step,
  testIdName,
  value,
}) => {
  return (
    <div>
      {label && <Label htmlFor={name}>{label}</Label>}
      <div
        className={clsx(
          'rounded-foreground flex items-center border border-main',
          disabled ? 'bg-fill-primary' : 'bg-system-background-secondary',
          borderClassNames,
        )}
      >
        <TextFieldContainer className={clsx('flex flex-col', inputHeight)}>
          <TextField
            isFormik={isFormik}
            autoComplete="off"
            disabled={disabled}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            data-testid={`currencyInput${testIdName}`}
            step={step}
            type="number"
            inputMode="decimal"
            value={value}
            min={0}
            pattern="[0-9]*"
            className={clsx(
              'h-full border-none disabled:bg-transparent',
              inputHelperText && 'py-2',
            )}
            fontClassNames="text-title2 leading-title2 font-stretch-condensed"
          />
          {inputHelperText && inputHelperText}
        </TextFieldContainer>
        <div className="flex flex-col ml-auto pr-4">
          <SelectorButton
            className="rounded-r-foreground p-2"
            type="button"
            data-testid={`currencySelection${testIdName}`}
            code={code}
            disabled={disabled || disabledSelector}
            icon={icon}
            onClick={onClick}
          >
            <CurrencyIcon icon={icon} />
            <Text variant="callout" className="text-label-primary">
              {code.split('_')[0]}{' '}
            </Text>
            <div className="w-5 h-5">
              {!disabledSelector && (
                <ChevronDownIcon className="w-5 h-5 ml-1 text-label-tertiary" />
              )}
            </div>
          </SelectorButton>
          {description}
        </div>
      </div>
      {errorText && <HelperText variant="error">{errorText}</HelperText>}
      {helperText && <HelperText>{helperText}</HelperText>}
    </div>
  );
};

export default CurrencySelector;
export type { CurrencySelectorProps, CurrencySelectorWitAutoswitchProps };
