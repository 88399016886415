import clsx from 'clsx';
import { FunctionComponent, ReactElement } from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import { getInputClasses } from './helpers';
import { InputBaseProps } from './types';

const StyledDateInput = styled.input`
  &[type='date']::-webkit-calendar-picker-indicator {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='%236B6F78'%3E%3Cpath fill-rule='evenodd' d='M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z' clip-rule='evenodd' /%3E%3C/svg%3E");
  }
`;

const InputBase: FunctionComponent<InputBaseProps> = ({
  disabled,
  inputRef,
  mask,
  guide,
  className,
  forceRewriteClassName,
  invalid,
  onBlur,
  iconLeft,
  iconRight,
  fontClassNames,
  borderClassNames,
  ...props
}): ReactElement => {
  const commonProps = {
    className:
      forceRewriteClassName ||
      clsx(
        ...getInputClasses({
          fontClassNames,
          borderClassNames,
          invalid,
          iconLeft: !!iconLeft,
          iconRight: !!iconRight,
        }),
        className,
      ),
    disabled,
    onBlur,
    ref: inputRef,
    ...props,
  };

  let inputElement: ReactElement;

  if (mask) {
    inputElement = (
      <MaskedInput {...commonProps} mask={mask} guide={guide} {...props} />
    );
  } else if (props.type === 'date') {
    inputElement = <StyledDateInput {...commonProps} {...props} />;
  } else {
    inputElement = <input {...commonProps} {...props} />;
  }

  if (iconLeft || iconRight) {
    return (
      <div className="relative">
        {iconLeft && (
          <div className="absolute top-0 bottom-0 flex items-center left-4">
            {iconLeft}
          </div>
        )}
        {inputElement}
        {iconRight && (
          <div className="absolute top-0 bottom-0 flex items-center right-4">
            {iconRight}
          </div>
        )}
      </div>
    );
  }

  return inputElement;
};

export default InputBase;
