/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';

export interface UseScriptProps {
  url: string;
  async?: boolean;
  defer?: boolean;
  onLoad?: (e: Event) => void;
  onError?: (e: ErrorEvent) => void;
  skip?: boolean;
  scriptTagAttributes?: Record<string, string>;
}

export interface UseScript {
  loaded: boolean;
  error: boolean;
}

const cachedScripts = new Set();

const useScript = ({
  async = true,
  defer = false,
  onError,
  onLoad,
  skip,
  url,
  scriptTagAttributes,
}: UseScriptProps): UseScript => {
  const [state, setState] = useState({
    loaded: cachedScripts.has(url),
    error: false,
  });

  useEffect(() => {
    // If the script is already loaded, don't even try to do it.
    // If skip prop is true, skip loading as well.
    if (state.loaded || skip) {
      return;
    }

    const script = document.createElement('script');

    script.src = url;
    // See: https://javascript.info/script-async-defer#dynamic-scripts
    // for info about the default `async` behavior of dynamically added scripts.
    script.async = async;
    script.defer = defer;

    if (scriptTagAttributes) {
      Object.entries(scriptTagAttributes).forEach(([key, value]) => {
        script.setAttribute(key, value);
      });
    }

    const onScriptLoad = (e: Event) => {
      // Add url to set so we don't load script twice
      cachedScripts.add(url);
      setState({
        loaded: true,
        error: false,
      });
      onLoad?.(e);
    };

    const onScriptError = (e: ErrorEvent) => {
      // Remove script from cache so we can retry
      cachedScripts.delete(url);
      setState({
        loaded: true,
        error: true,
      });
      onError?.(e);
    };

    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    // Add script to the document (start loading)
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  return {
    loaded: state.loaded,
    error: state.error,
  };
};

export default useScript;
