import clsx from 'clsx';
import { createElement, FunctionComponent, ReactElement } from 'react';
import { TextProps } from 'Text/types';
import { getClassNamesForVariant } from './getClassNamesForVariant';
import { getElementTypeForVariant } from './getElementTypeForVariant';

const Text: FunctionComponent<TextProps> = ({
  className,
  children,
  variant,
  as,
  bold,
  condensed,
  ...props
}): ReactElement => {
  const variantClassNames = getClassNamesForVariant({
    variant,
    bold,
    condensed,
  });

  const elementType = as || getElementTypeForVariant(variant);

  const classes = clsx(variantClassNames, className);

  return createElement(elementType, { ...props, className: classes }, children);
};

export default Text;
