import { ArrowRightIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Loader';
import Text from '../Text';
import {
  getColorsForVariant,
  getLoaderColorForVariant,
  getMarginClassName,
  getRadiusClassName,
} from './helpers';
import {
  ButtonElementProps,
  ButtonProps,
  isAnchorElement,
  isLinkElement,
} from './types';

const Button: FunctionComponent<ButtonProps> = ({
  loading,
  margin,
  radius,
  children,
  className,
  variant = 'primary',
  icon,
  hasArrowRightIcon,
  height = 12,
  ...otherProps
}) => {
  const commonProps = {
    className: clsx(
      variant !== 'clear' &&
        'p-4 w-full focus:outline-none focus:shadow-outline transition-colors',
      variant !== 'clear' &&
        'relative block text-center disabled:cursor-not-allowed',
      'flex justify-center items-center',
      variant !== 'clear' && getMarginClassName(margin),
      variant !== 'clear' && getRadiusClassName(radius),
      getColorsForVariant(variant),
      variant !== 'clear' && `h-${height}`,
      className,
    ),
    ...otherProps,
  } as ButtonElementProps;

  // If icon is null it means we explicitly passed null
  // and don't want to show it
  const showIcon = icon !== null && variant !== 'clear';

  // ArrowRightIcon will be default icon in case we didn't pass icon prop
  // and variant is either primary or danger
  const isArrowRightIconVisible =
    hasArrowRightIcon === undefined
      ? icon === undefined && ['primary', 'danger'].includes(variant)
      : hasArrowRightIcon;

  const body = loading ? (
    <Loader color={getLoaderColorForVariant(variant)} />
  ) : (
    <>
      {children}
      {showIcon && (
        <span className="absolute top-0 right-0 flex items-center h-full px-4">
          {isArrowRightIconVisible ? (
            <ArrowRightIcon className="w-6 h-6" />
          ) : (
            icon
          )}
        </span>
      )}
    </>
  );

  if (isLinkElement(commonProps)) {
    return <Link {...commonProps}>{body}</Link>;
  }

  if (isAnchorElement(commonProps)) {
    return <a {...commonProps}>{body}</a>;
  }

  return (
    <Text
      as="button"
      variant="headline"
      condensed
      data-testid="submitButton"
      type={commonProps?.type === 'submit' ? 'submit' : 'button'}
      {...commonProps}
    >
      {body}
    </Text>
  );
};

export default Button;
