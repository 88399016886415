var _a, _b, _c;
export var WalletMessageType;
(function (WalletMessageType) {
    WalletMessageType["READY"] = "wallet-ready";
    WalletMessageType["MESSAGE_PROXY"] = "wallet-message-proxy";
    WalletMessageType["MESSAGE_PROXY_RESPONSE"] = "wallet-message-proxy-response";
    WalletMessageType["PROMPT_CHANGE"] = "wallet-prompt-change";
    WalletMessageType["EXIT"] = "wallet-exit";
})(WalletMessageType || (WalletMessageType = {}));
// TODO: deprecate in either dhw or here
export var SecureWalletUxMode;
(function (SecureWalletUxMode) {
    SecureWalletUxMode["NoUx"] = "no-ux";
    SecureWalletUxMode["CloudBackupWallet"] = "cloud-backup-wallet";
    SecureWalletUxMode["CloudRestoreWallet"] = "cloud-restore-wallet";
    SecureWalletUxMode["ShowSeedPhrase"] = "show-seed-phrase";
    SecureWalletUxMode["VerifySeedPhrase"] = "verify-seed-phrase";
    SecureWalletUxMode["Prompt"] = "prompt";
})(SecureWalletUxMode || (SecureWalletUxMode = {}));
export var WalletType;
(function (WalletType) {
    WalletType["CUSTODIAL"] = "custodial";
    WalletType["NON_CUSTODIAL"] = "nonCustodial";
})(WalletType || (WalletType = {}));
export var WalletNetwork;
(function (WalletNetwork) {
    WalletNetwork["Ethereum"] = "ethereum";
    WalletNetwork["Bitcoin"] = "bitcoin";
})(WalletNetwork || (WalletNetwork = {}));
export var WalletChainsSupported = (_a = {},
    _a[WalletNetwork.Ethereum] = [1, 5, 137, 80001],
    _a[WalletNetwork.Bitcoin] = [0, 1],
    _a);
export var WalletNetworkSymbol;
(function (WalletNetworkSymbol) {
    WalletNetworkSymbol["ETH"] = "eth";
    WalletNetworkSymbol["BTC"] = "btc";
})(WalletNetworkSymbol || (WalletNetworkSymbol = {}));
export var WalletNetworkToSymbolMap = (_b = {},
    _b[WalletNetwork.Ethereum] = WalletNetworkSymbol.ETH,
    _b[WalletNetwork.Bitcoin] = WalletNetworkSymbol.BTC,
    _b);
export var WalletSymbolToNetworkMap = (_c = {},
    _c[WalletNetworkSymbol.ETH] = WalletNetwork.Ethereum,
    _c[WalletNetworkSymbol.BTC] = WalletNetwork.Bitcoin,
    _c);
