import { FunctionComponent } from 'react';
import Transition, {
  TransitionStatus,
} from 'react-transition-group/Transition';
import styled from 'styled-components';
import Header from '../Header';
import { SlideInModalProps } from './types';
import { slideBottomUp } from '../utils/transitionStyles';

import Container from '../Container';

const defaultStyle = {
  display: 'none',
  transition: 'transform 300ms ease-in-out',
  transform: 'translateY(100%)',
  minHeight: 'unset',
  height: 'fit-content',
  top: 'auto',
};

const Wrapper = styled.div<{ state: TransitionStatus }>`
  position: absolute;
  top: 0;
  left: 0;
  transition: background-color 300ms ease-in-out;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 10;
  ${({ state }) =>
    state === 'entered' && 'background-color: rgba(0, 0, 0, 0.5)'};
  ${({ state }) => state === 'exited' && 'background-color: rgba(0, 0, 0, 0)'};

  /**
    * This is a fix for mobile devices to avoid the modal to be scrollable
   */
  @media screen and (max-width: 575px) {
    position: fixed;
  }
`;

const timeout = { enter: 0, exit: 300 };

const SlideInModal: FunctionComponent<SlideInModalProps> = ({
  children,
  isOpen,
  onClose,
  'data-testid': dataTestId,
  slideInModalClassName,
  headerTitle = '',
  isHeaderCentered,
  style,
}) => {
  return (
    <Transition in={isOpen} timeout={timeout} unmountOnExit>
      {(state) => (
        <Wrapper onClick={onClose} state={state}>
          <div onClick={(e) => e.stopPropagation()} role="none">
            <Container
              modal
              data-testid={dataTestId}
              className={slideInModalClassName}
              style={{
                ...defaultStyle,
                ...slideBottomUp[state],
                ...style,
              }}
            >
              {onClose && (
                <Header
                  isHeaderCentered={isHeaderCentered}
                  data-testid="slide-in-modal-header"
                  onClosePress={onClose}
                  title={headerTitle}
                />
              )}
              {children}
            </Container>
          </div>
        </Wrapper>
      )}
    </Transition>
  );
};

export default SlideInModal;
