import clsx from 'clsx';
import { FunctionComponent, ReactElement } from 'react';
import Label from '../Label';
import Text from '../Text';
import CheckboxIcon from './CheckboxIcon';
import { HiddenCheckbox } from './styledComponents';
import { CheckboxProps } from './types';

const Checkbox: FunctionComponent<CheckboxProps> = ({
  className,
  checkboxClassName,
  checked,
  onChange,
  'data-testid': dataTestid,
  label,
  subLabel,
  variant = 'simple',
  highlightColor = 'primary',
  ...props
}): ReactElement => {
  const activeClasses =
    !props.disabled &&
    `hover:accent-${highlightColor} hover:border-accent-${highlightColor} focus:border-accent-${highlightColor} focus:shadow-input`;

  const checkedClasses =
    checked &&
    `bg-accent-fill-${highlightColor} border-accent-${highlightColor}`;

  const inputClasses = clsx(
    'border border-label-tertiary cursor-pointer flex-shrink-0 relative self-center h-4 w-4 rounded',
    {
      'border-label-tertiary bg-fill-secondary cursor-not-allowed':
        props.disabled,
    },
    checkedClasses,
    activeClasses,
    checkboxClassName,
  );

  return (
    <label
      className={clsx(
        'flex items-start',
        {
          'py-4 px-5 transition-colors cursor-pointer border rounded-foreground border-main':
            variant === 'boxed',
          'border-accent-primary bg-accent-fill-primary':
            checked && variant === 'boxed',
        },
        className,
      )}
      data-testid={dataTestid}
    >
      <HiddenCheckbox
        checked={checked}
        onChange={onChange}
        type="checkbox"
        {...props}
      />
      <div data-testid="policyAgreement" className={inputClasses}>
        {checked && <CheckboxIcon disabled={props.disabled} />}
      </div>
      <div className="flex flex-col justify-center ml-4">
        {label && (
          <Label
            as="span"
            textColor={
              variant === 'boxed'
                ? 'text-label-primary'
                : 'text-label-secondary'
            }
            margin="mb-0"
          >
            {label}
          </Label>
        )}
        {subLabel && (
          <Text variant="footnote" className="text-label-secondary mt-1">
            {subLabel}
          </Text>
        )}
      </div>
    </label>
  );
};

export default Checkbox;
