import debounce from 'lodash.debounce';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

interface MiddleTruncateProps {
  text: string;
  className?: string;
}

const MiddleTruncate: FunctionComponent<MiddleTruncateProps> = ({
  className,
  text,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [truncatedText, setTruncatedText] = useState('');

  const getOriginalTextWidth = useCallback((): number => {
    if (!containerRef.current) {
      return -1;
    }

    const computedStyle = window.getComputedStyle(containerRef.current, null);
    const fontFamily = computedStyle.getPropertyValue('font-family');
    const fontSize = computedStyle.getPropertyValue('font-size');

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
      return -1;
    }
    context.font = `${fontSize} ${fontFamily}`;
    return context.measureText(text).width;
  }, [text]);

  const calculateTruncate = useCallback(() => {
    setTruncatedText('');

    const textWidth = getOriginalTextWidth();
    if (!containerRef.current || textWidth < 1) {
      return;
    }

    const parentWidth = containerRef.current.parentElement?.offsetWidth;
    if (!parentWidth) {
      return;
    }

    if (textWidth > parentWidth) {
      const avgLetterSize = textWidth / text.length;
      const canFit = parentWidth / avgLetterSize;
      const delEachSide = (text.length - canFit + 5) / 2;
      const endLeft = Math.floor(text.length / 2 - delEachSide);
      const startRight = Math.floor(text.length / 2 + delEachSide);
      setTruncatedText(
        `${text.substr(0, endLeft)}...${text.substr(startRight)}`,
      );
    } else {
      setTruncatedText(text);
    }
  }, [text, getOriginalTextWidth, setTruncatedText]);

  useEffect(() => {
    const onResize = debounce(calculateTruncate, 150);
    if (containerRef.current) {
      window.addEventListener('resize', onResize);
      calculateTruncate();
    }
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [containerRef, calculateTruncate]);

  return (
    <div ref={containerRef} className={className}>
      {truncatedText}
    </div>
  );
};

export default MiddleTruncate;
