import clsx from 'clsx';
import { ComponentProps, FC, KeyboardEvent } from 'react';
import styled, { css } from 'styled-components';

interface CharacterProps extends ComponentProps<'input'> {
  focused: boolean;
  characterClassName?: string;
  highlightColor?: string;
}

const Container = styled.div`
  position: relative;
  height: 4rem;
`;

const HiddenInput = styled.input`
  outline: none;
  text-align: center;

  height: 100%;
  width: 100%;

  caret-color: transparent;
  color: transparent;
  background: transparent;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const CharacterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  pointer-events: none;
  cursor: pointer;

  transition: border-color 0.15s;
`;

const AnimatedCharacter = styled.span<{ hasValue: boolean }>`
  transition: transform 0.1s;
  transform: scale(0.8);

  ${(props) =>
    props.hasValue &&
    css`
      transform: scale(1);
    `}
`;

const Character: FC<CharacterProps> = ({
  value,
  focused,
  characterClassName,
  highlightColor = 'primary',
}) => {
  const handleHiddenInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    // Prevent anything from being typed into hidden input,
    // just in case.
    event.preventDefault();
  };

  return (
    <Container>
      {/*
        Used only for mobile devices in order to paste when press and hold.
        Parent component actually takes care of value when pasting.
      */}
      <HiddenInput
        type="number"
        autoComplete="off"
        onKeyDown={handleHiddenInputKeyDown}
      />
      <CharacterContainer
        className={clsx(
          'bg-system-background-secondary rounded-foreground',
          'text-label-primary text-title1 leading-title1 font-regular font-stretch-condensed',
          focused && `border border-accent-${highlightColor}`,
          characterClassName,
        )}
      >
        <AnimatedCharacter hasValue={!!value}>{value}</AnimatedCharacter>
      </CharacterContainer>
    </Container>
  );
};

export default Character;
